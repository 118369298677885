
import $ from 'jquery'
import 'jquery.transit'
import 'jquery-background-video'
import LazyLoad from 'vanilla-lazyload/dist/lazyload.js'
import 'intersection-observer';


/*
 * Main scripts.
 */

// Lazy load images.
new LazyLoad({
    elements_selector: ".lazy",
    to_webp: (process.env.NODE_ENV === 'production')
});

// Window loaded.
$(window).on('load', function() {
    $('body').addClass('loaded');
});

//Document ready
$(document).ready(function(){
	//background video
    $('.my-background-video').bgVideo({
	    fullScreen: true, // Sets the video to be fixed to the full window - your <video> and it's container should be direct descendents of the <body> tag
	    fadeIn: 500, // Milliseconds to fade video in/out (0 for no fade)
	    pauseAfter: 0, // Seconds to play before pausing (0 for forever)
	    showPausePlay: false // Show pause/play button
	});
});